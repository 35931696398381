@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

.ReactModal__Overlay {
  @apply origin-top scale-y-0 bg-green-1 opacity-0 transition-all duration-500 ease-out;
}

.ReactModal__Overlay--after-open {
  @apply scale-y-100 opacity-100 ease-out;
}

.ReactModal__Overlay--before-close {
  @apply opacity-0;
}

.ReactModal__Content {
  @apply opacity-0 transition-opacity delay-300;
}

.ReactModal__Content--after-open {
  @apply opacity-100;
}

.ReactModal__Content--before-close {
  @apply opacity-0;
}

.custom-select .react-select__indicator-separator {
  @apply hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-light-2;
  }
}

@layer components {
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
}

@layer utilities {
  .mask-right {
    -webkit-mask-image: url(/public/img/mask-right.svg);
    mask-image: url(/public/img/mask-right.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  .mask-right-2 {
    -webkit-mask-image: url(/public/img/mask-right-2.svg);
    mask-image: url(/public/img/mask-right-2.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .mask-right-3 {
    -webkit-mask-image: url(/public/img/mask-right-3.svg);
    mask-image: url(/public/img/mask-right-3.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  .mask-left {
    -webkit-mask-image: url(/public/img/mask-left.svg);
    mask-image: url(/public/img/mask-left.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: right;
    mask-position: right;
  }

  .mask-left-2 {
    -webkit-mask-image: url(/public/img/mask-left-2.svg);
    mask-image: url(/public/img/mask-left-2.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: right;
    mask-position: right;
  }

  .mask-left-3 {
    -webkit-mask-image: url(/public/img/mask-left-3.svg);
    mask-image: url(/public/img/mask-left-3.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: right;
    mask-position: right;
  }

  .mask-triangle-right {
    -webkit-mask-image: url(/public/img/mask-triangle-right.svg);
    mask-image: url(/public/img/mask-triangle-right.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  .mask-triangle-right-2 {
    -webkit-mask-image: url(/public/img/mask-triangle-right-2.svg);
    mask-image: url(/public/img/mask-triangle-right-2.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: right;
    mask-position: right;
  }

  .mask-triangle-left {
    -webkit-mask-image: url(/public/img/mask-triangle-left.svg);
    mask-image: url(/public/img/mask-triangle-left.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }

  .mask-triangle-menu {
    -webkit-mask-image: url(/public/img/mask-triangle-menu.svg);
    mask-image: url(/public/img/mask-triangle-menu.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }

  .mask-menu {
    -webkit-mask-image: url(/public/img/menu.svg);
    mask-image: url(/public/img/menu.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .mask-facebook {
    -webkit-mask-image: url(/public/img/facebook.svg);
    mask-image: url(/public/img/facebook.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .mask-linkedin {
    -webkit-mask-image: url(/public/img/linkedin.svg);
    mask-image: url(/public/img/linkedin.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .mask-chevron {
    -webkit-mask-image: url(/public/img/chevron.svg);
    mask-image: url(/public/img/chevron.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .mask-close {
    -webkit-mask-image: url(/public/img/close.svg);
    mask-image: url(/public/img/close.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem;
    mask-size: 1.25rem;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .mask-search {
    -webkit-mask-image: url(/public/img/search.svg);
    mask-image: url(/public/img/search.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .bg-gradient-home::after {
    background: linear-gradient(
      180deg,
      rgba(0, 78, 56, 0.9) 21.35%,
      rgba(0, 78, 56, 0.57) 62.5%,
      rgba(0, 78, 56, 0.28) 100%
    );
  }
  .scale-up-center {
    -webkit-animation: scale-up-center 15s cubic-bezier(0.445, 0.05, 0.55, 0.95)
      infinite alternate both;
    animation: scale-up-center 15s cubic-bezier(0.445, 0.05, 0.55, 0.95)
      infinite alternate both;
  }

  .animation-line {
    stroke-dasharray: 500;
    stroke-dashoffset: 0;
    animation: dash 1s linear;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 500;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-10-10 15:58:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.custom-select .ReactModalPortal .hide::after {
  display: none !important;
}
